import React from "react";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";

import { Heading3 } from "../heading";
import Mono, { MonoBold, MonoUppercase } from "../mono";
import RatioBox from "../ratio-box";

const StyledCard = styled.div`
  position: relative;
  z-index: 1;
  overflow: hidden;

  /* a:hover & {
    background-color: var(--color-yellow);
  } */
`;

// color
// color-burn
// color-dodge
// darken
// difference
// exclusion
// hard-light
// hue
// lighten
// luminosity
// multiply
// normal
// overlay
// saturation
// screen
// soft-light

const StyledImage = styled.div`
  position: relative;
  height: inherit;
  user-select: none;
  overflow: hidden;

  background-image: url(${props => props.image && props.image});
  background-size: cover;
  background-position: 50% 50%;
  // background-blend-mode: luminosity;
  // opacity: 0.5;
  // filter: brightness(95%);
  // filter: contrast(140%);
  // background-color: var(--color-${props => props.bgColor && props.bgColor});
  // transition-property: background-color;
  // transition-duration: 0.1s;
  // a:hover & {
  //   background-color: var(--color-blue);
  // }
`;

const StyledImageHover = styled.div`
  position: absolute;
  width: 100%;
  height: inherit;
  user-select: none;
  overflow: hidden;
  mix-blend-mode: screen;
  // opacity: 0;

  // background-image: url(${props => props.image && props.image});
  // background-size: cover;
  // background-position: 50% 50%;
  // background-blend-mode: luminosity;
  // background-color: var(--color-blue);

  a:hover & {
    opacity: 1;
  }
`;

const StyledContent = styled.div`
  text-align: center;
  padding: 0;
  margin: 0 auto;
  padding: 10px 10px;
`;

const StyledHeading = styled(Heading3)`
  font-family: var(--font-family-header);
  font-weight: normal;
  font-size: 65px;
  line-height: 60px;
  letter-spacing: normal;
  text-transform: capitalize;
  margin-bottom: 0!important;
  padding: 0 20px;
  ${breakpoint("xl")`
    font-size: 180px;
    line-height: 140px;
    margin-bottom: 60px!important;
  `}
`;

const StyledCountry = styled.div`
  display: inline-block;
  font-size: 16px;
  line-height: 78px;
  font-family: var(--font-family);
  letter-spacing: normal;
  padding: 3px;
  margin-left: 10px;
  width: 0;
  height: 0;
  position: absolute;
  margin-top: 3px;
  ${breakpoint("xl")`
    font-size: 18px;
    line-height: 140px;
    position: relative;
    margin-top: 0;
  `}
`;

const StyledTitle = styled.div`
  display: none;
  ${breakpoint("xl")`
    display: inline-block;
    position: absolute;
    font-size: 18px;
    line-height: 21px;
    font-family: var(--font-family-bold);
    letter-spacing: normal;
    border: 1px solid var(--color-orange);
    padding: 3px 7px;
    border-radius: 3px;
    text-transform: uppercase;
    vertical-align: top;
    margin-left: -20px;
  `}
`;

class Card extends React.Component {
  render() {
    var headerClass = "";
    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  
    if (isSafari) {
      headerClass = 'safari';
    }
    var title = this.props.author;
    var twolineheader = false;
    if (title.includes('&')) {
      const title_parts = title.split('&');
      var title_parts_1 = title_parts[0];
      var title_parts_2 = title_parts[1];
      twolineheader = true;
    }
  
    return (
      <StyledCard>
        <StyledContent>
          {this.props.author && !twolineheader &&  (
            <StyledHeading as="h3">
              {title}
              {this.props.country && (
                <StyledCountry className={headerClass}>
                  {this.props.country}
                </StyledCountry>
              )}
              {this.props.title && (
                <StyledTitle>{this.props.title}</StyledTitle>
              )}
            </StyledHeading>
          )}
          {this.props.author && twolineheader &&  (
            <StyledHeading as="h3">
              {title_parts_1} &<br/> {title_parts_2}
              {this.props.country && (
                <StyledCountry className={headerClass}>
                  {this.props.country}
                </StyledCountry>
              )}
              {this.props.title && (
                <StyledTitle>{this.props.title}</StyledTitle>
              )}
            </StyledHeading>
          )}
        </StyledContent>
      </StyledCard>
    );
  }
}

export default Card;
